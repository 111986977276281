import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import classNames from 'classnames'
import React from 'react'
import {ImageResizeOptions, WowImage} from 'wix-ui-tpa/cssVars'
import s from './event-image.scss'
import {classes} from './event-image.st.css'
import {EventImageProps} from './interfaces'

export const EventImage = ({containerRef, alt, image, className, doubleHeight}: EventImageProps) => {
  return (
    <div className={classNames(s.outerContainer, className)} ref={containerRef}>
      <div className={classNames(s.imageContainer, s.sizing, {[s.double]: doubleHeight})} data-hook={DH.eventImage}>
        {[
          {visibilityClass: classes.fitImage, resize: ImageResizeOptions.contain},
          {visibilityClass: classes.coverImage, resize: ImageResizeOptions.cover},
        ].map(({visibilityClass, resize}) => (
          <WowImage
            key={resize}
            className={classNames(classes.image, visibilityClass)}
            sourceWidth={image.width}
            sourceHeight={image.height}
            src={image.id}
            alt={alt}
            focalPoint={{x: 50, y: 50}}
            resize={resize}
            shouldUseLQIP
            fluid
          />
        ))}
      </div>
    </div>
  )
}
